<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{ zIndex: options.zIndex }"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>
      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="text-subtitle-1 white--text">조직 생성|공유 안내</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container fluid class="pb-0">
          <v-row
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" xs="12">
              <v-form>
                <v-text-field
                  v-model="firmInfo.wname"
                  v-validate="'required|max:30'"
                  :error-messages="errors.collect('wname')"
                  data-vv-name="wname"
                  required
                  placeholder="입력하세요"
                  name="wname"
                  label="✶ 조직명"
                  type="text"
                  class="mx-2"
                ></v-text-field>
                <v-text-field
                  v-model="firmInfo.addr1"
                  v-validate="'required|max:50'"
                  :error-messages="errors.collect('addr1')"
                  data-vv-name="addr1"
                  required
                  placeholder="입력하세요"
                  name="addr1"
                  label="✶ 주소"
                  type="text"
                  class="mx-2"
                ></v-text-field>
                <!-- <v-text-field
                  v-model="firmInfo.tel"
                  v-validate="{required: true, regex: [/^\d{2,3}-\d{3,4}-\d{4}$/]}"
                  :error-messages="errors.collect('tel')"
                  data-vv-name="tel"
                  required
                  placeholder="02-123-4567"
                  hint="02-123-4567"
                  name="tel"
                  label="✶ 전화번호"
                  type="text"
                  class="mx-2"
                ></v-text-field>
                <v-text-field
                  v-model="firmInfo.fax"
                  v-validate="{regex: [/^\d{2,3}-\d{3,4}-\d{4}$/]}"
                  :error-messages="errors.collect('fax')"
                  data-vv-name="fax"
                  name="fax"
                  label="팩스번호"
                  placeholder="02-123-4567"
                  hint="02-123-4567"
                  type="text"
                  class="mx-2"
                ></v-text-field> -->
                <v-text-field
                  v-model="firmInfo.tel"
                  v-validate="{required: true}"
                  :error-messages="errors.collect('tel')"
                  data-vv-name="tel"
                  required
                  placeholder="02-123-4567"
                  hint="02-123-4567"
                  name="tel"
                  label="✶전화번호"
                  type="text"
                  maxlength="50"
                  counter="50"
                  class="mx-2 my-1"
                ></v-text-field>
                <v-text-field
                  v-model="firmInfo.fax"
                  name="fax"
                  label="팩스번호"
                  placeholder="02-123-4567"
                  hint="02-123-4567"
                  type="text"
                  maxlength="50"
                  counter="50"
                  class="mx-2 my-1"
                ></v-text-field>
                <v-text-field
                  :value="firmInfo.mname"
                  label="관리자"
                  filled
                  shaped
                  disabled
                  class="mx-2"
                ></v-text-field>
                <v-text-field
                  :value="firmInfo.master"
                  label="관리자이메일"
                  filled
                  shaped
                  disabled
                  class="mx-2"
                ></v-text-field>
              </v-form>
            </v-col>
            <v-col cols="12" xs="12">
              <v-card class="elevation-0 mt-5 mb-5 blue-grey--text">
                <v-card-text>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

    </baseModal>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import ko from 'vee-validate/dist/locale/ko'

export default {
  components: {
    baseModal
  },

  props: [ 'firmInfo' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    options: {
      color: 'primary',
      width: 700,
      zIndex: 300
    },
    dictionary: {
      messages: ko.messages,
      attributes: {
        wname: '조직명',
        addr1: '주소',
        tel: '전화번호'
      }
    },
    saveData: {} // 참고: 넘어온 정보의 초기값을 담고 있다
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    dummy () {
    },

    sbpop (msg) {
      this.$store.commit('SB_POP', { msg })
    },

    open (title, options) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 중요: 넘어온 props.firmInfo 초기값 저장(취소시 필요)
        this.saveData = Object.assign({}, this.firmInfo)

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },

    async agree () {
      try {
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        this.dialog = false

        const { data } = await this.$axios.post('admin/firm/edit', this.firmInfo)
        if (!data.success) {
          this.resolve(false)
          throw new Error('정보수정에 실패했습니다')
        } else {
          this.resolve(true)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },

    cancel () {
      // 중요: 취소시 props 로 넘어온 firmInfo 를 저장한 데이터로 초기화 해야 함!
      this.firmInfo = Object.assign(this.firmInfo, this.saveData)
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem
}
</style>
