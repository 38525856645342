<template>
  <v-container fluid class="pa-0 ma-0">

    <v-toolbar dense flat color="grey lighten-2">
      <v-btn
        outlined
        color="primary"
        @click="editFirm">
        <v-icon small class="mr-1">mdi-pencil</v-icon>
        수정
      </v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn text icon>
        <v-icon small>apps</v-icon>
      </v-btn> -->
    </v-toolbar>

    <v-card
      tile
      :elevation="0"
    >
      <v-card-text>
        <v-row
          align="center"
          justify="center"
          no-gutters
        >
          <v-col cols="12" xs="12">
            <v-text-field
              :value="firmInfo.wname"
              label="조직명"
              filled
              shaped
              readonly
              class="mx-2"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12">
            <v-text-field
              :value="firmInfo.addr1"
              label="주소"
              filled
              shaped
              readonly
              class="mx-2"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12">
            <v-text-field
              :value="firmInfo.tel"
              label="전화번호"
              filled
              shaped
              readonly
              class="mx-2"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12">
            <v-text-field
              :value="firmInfo.fax"
              label="팩스번호"
              filled
              shaped
              readonly
              class="mx-2"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12">
            <v-text-field
              :value="firmInfo.mname"
              label="운영관리자"
              filled
              shaped
              readonly
              class="mx-2"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12">
            <v-text-field
              :value="firmInfo.master"
              label="관리자계정"
              filled
              shaped
              readonly
              class="mx-2"
            ></v-text-field>
          </v-col>
          <!--
          <v-col cols="12" xs="12">
            <v-list>
              <v-list-item>
                <v-list-item-content class="subheading">
                  <v-list-item-title>첨부파일저장규칙</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-action class="text-body-1 grey--text mr-3">소송</v-list-item-action>
                <v-list-item-content class="text-body-2">
                  <v-list-item-title>조직명 -  업무부서 - 소송 - 사건번호 - 첨부파일명</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action class="text-body-1 grey--text mr-3">자문</v-list-item-action>
                <v-list-item-content class="text-body-2">
                  <v-list-item-title>조직명 - 업무부서 - 자문 - 사건번호 - 첨부파일명</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action class="text-body-1 grey--text mr-3">인명부</v-list-item-action>
                <v-list-item-content class="text-body-2">
                  <v-list-item-title>조직명 - 업무부서 - 인명부 - 사건번호 - 첨부파일명</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action class="text-body-1 grey--text mr-3">전자결재</v-list-item-action>
                <v-list-item-content class="text-body-2">
                  <v-list-item-title>조직명 - 기안자명 - 첨부파일명</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action class="text-body-1 grey--text mr-3">물품구매</v-list-item-action>
                <v-list-item-content class="text-body-2">
                  <v-list-item-title>조직명 - 물품구매관리팀 - 거래처명 - 첨부파일명</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action class="text-body-1 grey--text mr-3">인사정보</v-list-item-action>
                <v-list-item-content class="text-body-2">
                  <v-list-item-title>조직명 - 관리자팀 - 조직원명 - 첨부파일명</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <div class="pt-2 text-body-2 grey--text">
                  첨부파일은 관리자계정의 구글드라이브에 저장됩니다.<br>
                  그러므로, 해당 계정의 구글 드라이브의 지정 폴더 및 문서를 삭제하거나 경로 변경시에 정상적인 이용이 불가능합니다.
                </div>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" xs="12" v-if="show2">
            <v-list class="mb-4">
              <v-list-item>
                <v-list-item-content class="subheading mb-0 pb-0">
                  <v-list-item-title>독립로그인 URL</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="text-body-2 font-weight-medium mr-3">https://www.lawork.co.kr</span>
                    <v-icon small @click="dummy">file_copy</v-icon>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <div class="elevation-0 pt-2 text-body-2 grey--text">
                  귀사의 로고를 활용하여, 독립된 URL 페이지를 통해 로그인 할 수 있는 서비스입니다. [유료]<br>
                  특히, 의뢰인뷰 서비스를 진행하는 경우, 본 서비스를 통해 의뢰인에게 신뢰감을 줄 수도 있을 것입니다.<br>
                  필요한 경우, 고객센터로 문의하시기 바랍니다.
                </div>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" xs="12">
            <v-list class="mb-4">
              <v-list-item>
                <v-list-item-content class="subheading mb-0 pb-0">
                  <v-list-item-title>기존 조직 초기화</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="text-body-2 font-weight-medium">기존 조직에 데이터를 초기화하여 폐쇄를 원한다면, 고객센터로 문의하시기 바랍니다.</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <div class="elevation-0 pt-0 text-body-2 grey--text">
                  초기화하면, 공유자들이 더이상 이 조직에 접속할 수 없으며, 기존에 등록된 일체의 정보도 영구 폐기됩니다.<br>
                  단, 관리자계정의 구글드라이브에 저장된 첨부파일은 그대로 존재합니다.
                </div>
              </v-list-item>
            </v-list>
          </v-col>
          -->
          <v-col cols="12" xs="12">
            <v-list>
              <v-list-item>
                <v-list-item-content class="subheading">
                  <v-list-item-title class="indigo--text text--darken-1" style="font-weight: 500;">
                    <v-icon class="mr-1 mb-1" color="indigo darken-1">mdi-file-cog</v-icon>
                    첨부파일저장규칙
                    </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="text-body-2">
                  <v-list-item-title>관리자 계정 구글드라이브 > LAWORK</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <div class="pt-2 text-body-2 grey--text text--darken-1">
                  첨부파일은 관리자계정의 구글드라이브에 저장됩니다.<br>
                  그러므로, 해당 계정의 구글 드라이브의 지정 폴더 및 문서를 삭제시에 정상적인 이용이 불가능합니다.
                </div>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" xs="12">
            <v-list class="mb-4">
              <v-list-item>
                <v-list-item-content class="subheading">
                  <v-list-item-title class="indigo--text text--darken-1" style="font-weight: 500;">
                    <v-icon class="mr-1 mb-1" color="indigo darken-1">mdi-cog-refresh</v-icon>
                    조직 초기화
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="text-body-2">
                  <v-list-item-title>조직의 데이터를 초기화하거나 폐쇄하기를 원한다면, 고객센터로 문의하시기 바랍니다.</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <div class="elevation-0 pt-0 text-body-2 grey--text text--darken-1">
                  초기화하면, 공유자들이 더이상 이 조직에 접속할 수 없으며, 기존에 등록된 일체의 정보도 영구 폐기됩니다.<br>
                  단, 관리자계정의 구글드라이브에 저장된 첨부파일은 그대로 존재합니다.
                </div>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <editFirm-dialog :firmInfo="firmInfo" ref="editFirmDialog"></editFirm-dialog>

  </v-container>
</template>

<script>
import editFirmDialog from '@/components/admin/EditFirmDialog'

export default {
  components: {
    editFirmDialog
  },

  data: () => ({
    show2: true,
    firmInfo: {
      wname: '',
      addr1: '',
      tel: '',
      fax: '',
      mname: '',
      master: ''
    }
  }),

  mounted () {
    // 중요: 정상적으로 로그인하지 않으면 콘솔에 에러가 나는데.. 이 에러는 오히려 콘솔창에 생기라고 놔둬야 한다!
    // 이미 router.js 에서 로그인하지 않은경우 처리하므로 다시 '/' 로 뺄 필요도 없다..
    if (!this.$store.state.ui.dbcode) {
      // this.redirect('/')
    }

    this.getFirmInfo()
  },

  methods: {
    dummy () {
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 참고: 조직정보 가져오기
    async getFirmInfo () {
      try {
        const dbcode = this.$store.state.ui.dbcode
        // console.log(dbcode)
        const { data } = await this.$axios.get(`admin/firm/profile/${dbcode}`)
        if (!data.success) throw new Error('조직 정보를 가져오지 못했습니다.')
        // console.log(data.firmInfo)
        this.firmInfo = Object.assign(this.firmInfo, data.firmInfo)
      } catch (e) {
        this.sbpop(e)
      }
    },
    async editFirm () {
      try {
        if (await this.$refs.editFirmDialog.open('조직 생성|공유 안내')) {
          this.getFirmInfo() // 정상이면 리프레시
        } else {
          this.getFirmInfo() // 실패라도 리프레시
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
